.fa {
	margin-left:1em;
}

.fa-green {
	color:green;
}

.fa-red {
	color:red;
}

.fa-green:hover {
	color:lightgreen;
	font-size:20px;
}
.fa-red:hover{
	color:darkred;
	font-size:20px;
}

.fa-refresh:hover {
	color:lightblue;
}

.table-top {
	height:45%;
	max-height:45%;
	overflow:auto;
	margin-bottom:10px;
}

.table-left {
	height:45%;
	max-height:45%;
	float:left;
	width:49%;
	overflow:auto;
}

.table-right {
	height: 45%;
	max-height: 45%;
	width:49%;
	overflow:auto;
	float:right;
}

.table-small {
	height:25%;
	max-height:25%;
	overflow:auto;
}