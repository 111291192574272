.centered-login {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-box {
  border: 1px solid black;
  padding:2em;
  z-index : -1;
}
